
export default {
	name: "CoreBlockNavItem",
	props: {
		site: {
			type: Object,
			default: () => {},
		},
		parentLinkUrl: {
			type: String,
			default: "",
		},
		navitem: {
			type: Object,
			default: () => {
				return {
					id: "",
					title: "",
					openInNewTab: false,
					isFolder: "",
				};
			},
		},
		tier: {
			type: Number,
			default: 1,
		},
		isBuilderMobile: {
			type: Boolean,
			default: false,
		},
		isFooter: {
			type: Boolean,
			default: false,
		},
		isNavOpen: {
			type: Boolean,
			default: false,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showDropDown: false,
			autoCollapse: true,
		};
	},
	computed: {
		isFolder() {
			return this.$store?.getters["site/pageIsFolder"](this.navitem.id);
		},
		isInternalLink() {
			try {
				return JSON.parse(this.navitem?.link)?.url?.startsWith("http")
					? false
					: true;
			} catch {
				return this.navitem?.link?.url?.startsWith("http")
					? false
					: true;
			}
		},
		navItems() {
			const pages = [
				...this?.site?.pages.filter((item) => {
					return !item.isPrivate;
				}),
			];
			return pages;
			// return this?.site?.navigation?.links || [];
		},
		fullLinkUrl() {
			let url;
			try {
				url = JSON.parse(this.navitem?.link)?.url;
			} catch {
				url = this.navitem.link?.url;
			}
			const homepageId = this?.site?.homepageId;
			if (this.isInternalLink && !url) {
				return `${
					this.parentLinkUrl !== "/" && this.parentLinkUrl !== ""
						? this.parentLinkUrl
						: ""
				}/${this.navitem.handle}`;
			} else if (this.isInternalLink && url) {
				const page = this?.site?.pages.find(
					(p) => p.handle == url.substring(1)
				);
				if (page?.id == homepageId) {
					return "/";
				}
				return url;
			}
			return url;
		},
		subPages() {
			const pageSettings = this.parseString(this.navitem.settings);
			if (pageSettings?.pageAsFolder) return [];

			return this?.navItems.filter(
				(nI) => nI.parentPageId == this.navitem.id
			);
		},
		target() {
			return this.navitem.openInNewTab || !this.isInternalLink
				? "_blank"
				: "_self";
		},
		hasChildren() {
			return this.subPages?.length;
		},
		activeLink() {
			return this.$route?.path.includes(`/${this.navitem?.handle}`);
		},
	},
	watch: {
		isNavOpen() {
			if (!this.isNavOpen && this.autoCollapse) this.showDropDown = false;
		},
	},
	methods: {
		toggleDropDown() {
			this.showDropDown = !this.showDropDown;
		},
		onClick(event, closeNav = true) {
			if (this.autoCollapse) this.showDropDown = false;
			this.$emit("click", event, closeNav);
		},
		parseString(str) {
			try {
				return JSON.parse(str);
			} catch {
				return str;
			}
		},
	},
};
