
import { sitePath } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlock",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		disableLink: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		dataSite() {
			// this is the site object used in both the generator and the builder
			return sitePath(this.$store);
		},

		blockTag() {
			if (this.settings?.linkUrl && !this.isEditable) {
				return this.internalLink ? "NuxtLink" : "a";
			}
			return "div";
		},
		linkUrl() {
			if (this.disableLink) {
				return "";
			}
			if (this.settings?.linkUrl?.charAt(0) === "#") {
				return {
					hash: this.settings.linkUrl,
					path: this.$route.path,
				};
			}
			return this.settings?.linkUrl;
		},
		linkTarget() {
			return this.settings.linkInternal ? "_self" : "_blank";
		},
		internalLink() {
			return (
				this?.settings?.linkInternal ||
				this.settings?.linkUrl?.startsWith("/")
			);
		},
		blockComponent() {
			switch (this.settings.type) {
				case "button":
					return "CoreBlockButton";
				case "breadcrumbs":
					return "CoreBlockBreadcrumbs";
				case "contact-card":
					return "CoreBlockContactCard";
				case "contact-hero":
					return "CoreBlockContactHero";
				case "columns":
					return "CoreBlockColumns";
				case "container":
				case "section":
				case "accordion":
				case "toggle":
				case "tabs":
					return "CoreBlockContainer";
				case "content-card":
					return "CoreBlockContentCard";
				case "divider":
					return "CoreBlockDivider";
				case "file":
					return "CoreBlockFile";
				case "grid":
					return "CoreBlockGrid";
				case "heroComponent":
					return "CoreBlockHeroComponent";
				case "hero":
					return "CoreBlockHero";
				case "calculators":
					return "CoreBlockCalculators";
				case "html":
					return "CoreBlockHtml";
				case "icon":
					return "CoreBlockIcon";
				case "image":
					return "CoreBlockImage";
				case "media":
					return "CoreBlockMedia";
				case "rows":
					return "CoreBlockRows";
				case "social-links":
					return "CoreBlockSocialLinks";
				case "text":
					return "CoreBlockText";
				case "video":
					return "CoreBlockVideo";
				case "pencil-banner":
					return "CoreBlockPencilBanner";
				case "rich-text":
					return "CoreBlockRichText";
				case "form":
					return "CoreBlockForm";
				case "calendly":
					return "CoreBlockCalendly";
				case "map":
					return "CoreBlockMap";
				case "select":
					return "CoreBlockSelect";
				case "anchor-link-nav":
					return "CoreAnchorLinkNavigation";
			}
			return "CoreBlockUnknown";
		},
		blockId() {
			return this.settings.id;
		},
		blockStyle() {
			const styleSetting = this.settings.style || "";
			return [styleSetting]
				.join("\n")
				.replaceAll("#id", `#${this.blockId}`);
		},
		blockType() {
			return this.settings.type;
		},
		blockVariants() {
			const variants = [];
			if (this.settings.variants) {
				for (const [variantKey, variantValue] of Object.entries(
					this.settings.variants
				)) {
					// go through every variant saved that is not of the "_custom" key
					if (variantKey !== "_custom") {
						// variants defined without options are just toggles
						// and if they are true, then apply the variant key
						if (variantValue === true) {
							variants.push(variantKey);
						}
						// variants defined with specific string value options
						// should apply the selected value
						else {
							variants.push(variantValue);
						}
					}
				}
			}
			if (!variants.length) {
				variants.push("base");
			}
			return variants;
		},
		blockClasses() {
			const classList = ["block", `block_${this.blockType}`];
			this.blockVariants.forEach((variant) => {
				if (variant) {
					classList.push(`block_${this.blockType}_${variant}`);
				}
			});
			// anything added to the _custom key is a string w/ space delimited custom classes
			if (this.settings.variants?._custom) {
				classList.push(this.settings.variants._custom.split(" "));
			}
			// Add global animation from site settings
			if (this.dataSite?.animationStyle) {
				classList.push(
					"animate__animated animate__" + this.dataSite.animationStyle
				);
			}
			return classList;
		},
	},
};
